/**
 * Route names need to be unique. Therefore we utilize an enum to ensure no collisions
 * When adding a new route the name should be added here first
 * and then referenced in the corresponding `router.ts` file and sidebar item list.
 * Names should be human-readable so they can be used as labek for sidebar item list.
 */
export enum RouteNames {
  LOGIN_CALLBACK = 'LoginCallback',
  APP = 'App',
  PROJECTS = 'PROJECTS',
  DEBUG = 'Debug',
  TRACES = 'Traces',
  TRACE = 'Trace',
  PLAYGROUND = 'Playground',
  KNOWLEDGE = 'Knowledge',
  COLLECTIONS = 'Collections',
  SEARCH = 'Search',
  EVALUATE = 'Evaluate',
  FINETUNE = 'Finetune',
  DATASET_HOME = 'Datasets',
  DATASETS = 'DatasetsList',
  DATASET = 'DatasetDetail',
  BENCHMARK_HOME = 'Benchmarks',
  BENCHMARKS = 'BenchmarksList',
  BENCHMARK = 'BenchmarkDetail',
  MONITOR = 'Monitor',
  NOTFOUND = 'NotFound',
  UNAUTHORIZED = 'Unauthorized',
}
