import { http as baseHttp, Strategies, type HttpError } from '@aleph-alpha/lib-http';

interface StudioHttpError {
  error: string;
}

function isStudioBackendHttpError(content: StudioHttpError | string): content is StudioHttpError {
  return typeof content === 'object' && 'error' in content;
}

function isString(content: StudioHttpError | string): content is string {
  return typeof content === 'string';
}

export const errorInterceptor = async (error: HttpError<'ky'>) => {
  const { response } = error;

  if (response.status && response.statusText) {
    const content: StudioHttpError | string = await response.json();

    let message: string | undefined;
    if (isStudioBackendHttpError(content)) {
      message = content.error;
    } else if (isString(content)) {
      message = content;
    } else {
      JSON.stringify(content);
    }

    const customError = new Error(`Status ${response.status}: ${message}`);

    customError.name = 'HTTPError';
    customError.stack = error.stack;

    (customError as HttpError<'ky'>).response = response;

    return customError as HttpError<'ky'>;
  }
  return error;
};

const http = baseHttp(Strategies.ky, {
  baseURL: '',
});

http.addHooks({
  beforeError: [errorInterceptor],
});

export const addBearerToken = (type, token) => {
  http.updateConfig({
    headers: {
      Authorization: `${type} ${token}`,
    },
  });
};

export default http;
